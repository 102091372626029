import React from 'react';
import ReactDOM from 'react-dom';

import './assets/main.css';
import {setGlobal} from "reactn";
import dataService from './utils/data';
import authService from './utils/auth';

import * as Sentry from '@sentry/browser';

let appUuid = '9db96003-45d0-4ce9-bc5a-3629657d50f7';
let release = 'production';
if (process.env.NODE_ENV !== 'production') {
    appUuid += '-dev';
    release = 'dev';
}

Sentry.init({dsn: "https://ed3fe24916db41c392e72f8fda9b8c03@o386474.ingest.sentry.io/5243717", release});

setGlobal({
    user: {loggedIn: false}
});

dataService.init(appUuid);
authService.init();


import('./AppContainer').then(AppContainer => {
    ReactDOM.render(
        <React.StrictMode>
            <AppContainer.default />
        </React.StrictMode>,
        document.getElementById('root')
    );
});

